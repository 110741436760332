// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtpInput_container__zUuTi {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.OtpInput_container__zUuTi > input {
  width: 75px;
  height: 87px;
  border-radius: 10px;
  outline: 0.6px solid var(--grey4);
  border: 0.6px solid var(--grey4);
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  text-align: center;
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.OtpInput_container__zUuTi > input:focus {
  outline: 0.6px solid var(--purple);
}

.OtpInput_container__zUuTi > input[type="number"]::-webkit-outer-spin-button,
.OtpInput_container__zUuTi > input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/OtpInput/OtpInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,iCAAiC;EACjC,gCAAgC;EAChC,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n}\n\n.container > input {\n  width: 75px;\n  height: 87px;\n  border-radius: 10px;\n  outline: 0.6px solid var(--grey4);\n  border: 0.6px solid var(--grey4);\n  font-family: Montserrat;\n  font-size: 32px;\n  font-weight: 500;\n  line-height: 38.4px;\n  text-align: center;\n  color: var(--black);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.container > input:focus {\n  outline: 0.6px solid var(--purple);\n}\n\n.container > input[type=\"number\"]::-webkit-outer-spin-button,\n.container > input[type=\"number\"]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `OtpInput_container__zUuTi`
};
export default ___CSS_LOADER_EXPORT___;
