const SuccessIcon = () => {
  return (
    <svg
      width="85"
      height="84"
      viewBox="0 0 85 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="42.5" cy="42" r="42" fill="#E5CEF2" />
      <circle cx="42.5" cy="42" r="35" fill="#5C068C" />
      <path
        d="M39.6921 52.454L57.1596 34.776C57.1598 34.7757 57.16 34.7755 57.1602 34.7753C57.3373 34.5969 57.4775 34.3852 57.5731 34.1524C57.6688 33.9194 57.718 33.6697 57.718 33.4177C57.718 33.1657 57.6688 32.916 57.5731 32.683C57.4774 32.4501 57.3372 32.2383 57.1599 32.0599C56.8052 31.7021 56.3238 31.5 55.8205 31.5C55.3172 31.5 54.8357 31.7021 54.481 32.0599L54.4807 32.0601L39.3372 47.3538L31.3205 39.221C30.9761 38.8478 30.4988 38.6259 29.9917 38.6061C29.481 38.5863 28.9848 38.7732 28.6121 39.1234C28.2395 39.4735 28.0207 39.9577 28.0014 40.4692C27.9821 40.9806 28.1637 41.4802 28.5084 41.8577L28.5151 41.8651L28.522 41.8721L38.9808 52.454L39.3364 52.8139L39.6921 52.454Z"
        fill="#FEFDFF"
        stroke="#FEFDFF"
      />
    </svg>
  );
};

export default SuccessIcon;
