import React, { useContext, useEffect, useState } from "react";
import TransactionsList from "../../Components/TransactionsList/TransactionsList";
import { UserContext } from "../../Context/UserContext";
import { capitalizeEachWord } from "../../HelperFunctions/capitalize";
import { selectedDatesType, transactionType } from "../../Types";
import { getTodaysDate } from "../../HelperFunctions/getTodaysDate";
import { checkTransferStatus } from "../../Services/services";
import { generateId } from "../../HelperFunctions/generateId";
import UssdTransactionDetails from "../UssdTransactionsDetails/UssdTransactionsDetails";

type UssdTransactionsTypes = {
  onClose: () => void;
  onSuccess: () => void;
  sessionId: string;
};

const UssdTransactions = ({
  onClose,
  onSuccess,
  sessionId,
}: UssdTransactionsTypes) => {
  // COntext
  const { user } = useContext(UserContext);

  // States
  const [selectedDates, setSelectedDates] = useState<selectedDatesType>({
    startDate: getTodaysDate() || "",
    endDate: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [transactions, setTransactions] = useState<transactionType[]>([]);
  const [step, setStep] = useState(1);
  const [activeTransaction, setActiveTransaction] =
    useState<transactionType | null>(null);
  const [paymentMethodFilter, setPaymentMethodFilter] = useState("All");

  // Utils
  const handleCheckTransferStatus = async () => {
    setLoading(true);

    try {
      const response = await checkTransferStatus({
        requestId: generateId(),
        sessionId,
        transactionDate: selectedDates?.startDate,
      });

      if (response.data) {
        setError("");
        setTransactions(response?.data?.data);
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  if (step === 1) {
    return (
      <TransactionsList
        title="All Transactions"
        accountName={capitalizeEachWord(user?.accountName as string)}
        transactions={transactions}
        onClose={onClose}
        onSuccess={() => setStep(2)}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        loading={loading}
        setActiveTransaction={setActiveTransaction}
        error={error}
        paymentMethodFilter={paymentMethodFilter}
        setPaymentMethodFilter={setPaymentMethodFilter}
        getTransactions={handleCheckTransferStatus}
      />
    );
  } else {
    return (
      <UssdTransactionDetails
        onSuccess={onSuccess}
        onClose={() => setStep(1)}
        data={activeTransaction}
        paymentMethodFilter={paymentMethodFilter}
      />
    );
  }
};

export default UssdTransactions;
