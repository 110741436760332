import { useContext, useState } from "react";
import CloseIcon from "../../Assets/SVGIcons/CloseIcon/CloseIcon";
import Button from "../../Components/Button/Button";
import classes from "./AccountBlockMyAccount.module.css";
import { validateAccountNumber } from "../../Services/services";
import { generateId } from "../../HelperFunctions/generateId";
import Notifications from "../../Components/Notofications/Notofications";
import { UserContext } from "../../Context/UserContext";
import { capitalizeEachWord } from "../../HelperFunctions/capitalize";

type AccountBlockMyAccountTypes = {
  onClose: () => void;
  onSuccess: () => void;
  secondaryError: string;
};

const AccountBlockMyAccount = ({
  onClose,
  onSuccess,
  secondaryError,
}: AccountBlockMyAccountTypes) => {
  // States
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Utils
  const handleBlockMyAccount = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await validateAccountNumber({
        requestId: generateId(),
        accountNumber: user?.accountNumber as string,
      });

      if (response?.data) {
        onSuccess();
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  // Context
  const { user } = useContext(UserContext);

  return (
    <section className={classes.container}>
      <CloseIcon onClose={onClose} />

      <h3>Block My Account</h3>

      {error ||
        (secondaryError && (
          <Notifications message={error || secondaryError} severity="error" />
        ))}

      <div className={classes.accountNameSection}>
        <h4>Account Name</h4>
        <p> {capitalizeEachWord(user?.accountName as string)}</p>
      </div>
      <div className={classes.accountNameSection}>
        <h4>Phone Number</h4>
        <p> {user?.mobileNumber}</p>
      </div>
      <div className={classes.accountNameSection}>
        <h4>Date of Birth</h4>
        <p> {user?.dateOfBirth.split(" ")[0].replaceAll("/", "-")}</p>
      </div>

      <div className={classes.roundDivider}></div>
      <Button onClick={handleBlockMyAccount} loading={loading}>
        Block Account
      </Button>
      <p>
        NB: If you block your account will only be able to activate it by
        visiting a nearest FCMB branch.
      </p>
    </section>
  );
};

export default AccountBlockMyAccount;
