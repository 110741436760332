const BlockMyCardIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.29 8.46753H0.75V9.96753H13.5C14.2987 9.25448 15.2547 8.74048 16.29 8.46753Z"
        fill="#5F138D"
      />
      <path
        d="M20.25 5.7825C20.248 5.24406 20.0333 4.72823 19.6525 4.34749C19.2718 3.96675 18.7559 3.75198 18.2175 3.75H2.7825C2.24406 3.75198 1.72823 3.96675 1.34749 4.34749C0.966748 4.72823 0.751976 5.24406 0.75 5.7825V6.9675H20.25V5.7825Z"
        fill="#5F138D"
      />
      <path
        d="M0.75 11.4675V16.0725C0.75 16.3394 0.802572 16.6037 0.904715 16.8503C1.00686 17.0969 1.15657 17.321 1.34531 17.5097C1.53404 17.6985 1.7581 17.8482 2.0047 17.9503C2.25129 18.0525 2.51559 18.105 2.7825 18.105H12C11.4655 17.0712 11.2075 15.9167 11.2508 14.7537C11.2942 13.5907 11.6375 12.4587 12.2475 11.4675H0.75ZM5.445 15.5325H4.1625C3.96359 15.5325 3.77282 15.4535 3.63217 15.3129C3.49152 15.1722 3.4125 14.9814 3.4125 14.7825C3.4125 14.5836 3.49152 14.3929 3.63217 14.2522C3.77282 14.1115 3.96359 14.0325 4.1625 14.0325H5.445C5.64391 14.0325 5.83468 14.1115 5.97533 14.2522C6.11598 14.3929 6.195 14.5836 6.195 14.7825C6.195 14.9814 6.11598 15.1722 5.97533 15.3129C5.83468 15.4535 5.64391 15.5325 5.445 15.5325Z"
        fill="#5F138D"
      />
      <path
        d="M18 9.75C16.9617 9.75 15.9466 10.0579 15.0833 10.6348C14.2199 11.2117 13.547 12.0316 13.1496 12.9909C12.7523 13.9502 12.6483 15.0058 12.8509 16.0242C13.0535 17.0426 13.5535 17.9781 14.2877 18.7123C15.0219 19.4465 15.9574 19.9466 16.9758 20.1491C17.9942 20.3517 19.0498 20.2477 20.0091 19.8504C20.9684 19.453 21.7883 18.7801 22.3652 17.9167C22.9421 17.0534 23.25 16.0384 23.25 15C23.25 13.6076 22.6969 12.2723 21.7123 11.2877C20.7277 10.3031 19.3924 9.75 18 9.75ZM19.875 15.75H16.125C15.9261 15.75 15.7353 15.671 15.5947 15.5303C15.454 15.3897 15.375 15.1989 15.375 15C15.375 14.8011 15.454 14.6103 15.5947 14.4697C15.7353 14.329 15.9261 14.25 16.125 14.25H19.875C20.0739 14.25 20.2647 14.329 20.4053 14.4697C20.546 14.6103 20.625 14.8011 20.625 15C20.625 15.1989 20.546 15.3897 20.4053 15.5303C20.2647 15.671 20.0739 15.75 19.875 15.75Z"
        fill="#5F138D"
      />
    </svg>
  );
};

export default BlockMyCardIcon;
