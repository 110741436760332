import Header from "../../Containers/Header/Header";
import classes from "./Layout.module.css";

type LayoutTypes = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutTypes) => {
  return (
    <main className={classes.container}>
      <Header />
      <section>{children}</section>
    </main>
  );
};

export default Layout;
