import HomePage from "../Containers/HomePage/HomePage";
import { routes } from "./routes";

export const routeComponents = [
  {
    route: routes.BASE_URL,
    title: "HomePage",
    component: <HomePage />,
  },
];
