import { Dispatch, SetStateAction, useContext, useState } from "react";
import AccountAuthenticationAccountNumber from "../AccountAuthenticationAccountNumber/AccountAuthenticationAccountNumber";
import AccountAuthenticationOtp from "../AccountAuthenticationOtp/AccountAuthenticationOtp";
import { validateAccountNumber } from "../../Services/services";
import { generateId } from "../../HelperFunctions/generateId";
import { UserContext } from "../../Context/UserContext";

type AccountAuthenticationTypes = {
  header: string;
  onClose: () => void;
  onSuccess: () => void;
  sessionId?: string;
  setSessionId?: Dispatch<SetStateAction<string>>;
};

const AccountAuthentication = ({
  header,
  onClose,
  onSuccess,
  sessionId,
  setSessionId,
}: AccountAuthenticationTypes) => {
  // States
  const [authStep, setAuthStep] = useState(1);
  const [accountNumber, setAccountNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Context
  const { setUser } = useContext(UserContext);

  //   Utils
  const onAuthSuccess = () => {
    setAuthStep((prevState) => prevState + 1);
  };

  const handleAccountNumberValidation = async (retry: boolean) => {
    setLoading(true);
    setError("");

    try {
      const response = await validateAccountNumber({
        requestId: generateId(),
        accountNumber,
      });

      setUser(response?.data?.data);

      if (setSessionId) {
        setSessionId(response?.data?.sessionId);
      }

      if (!retry) {
        onAuthSuccess();
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  if (authStep === 1) {
    return (
      <AccountAuthenticationAccountNumber
        header={header}
        onClose={onClose}
        accountNumber={accountNumber}
        setAccountNumber={setAccountNumber}
        onClick={() => handleAccountNumberValidation(false)}
        loading={loading}
        error={error}
      />
    );
  } else if (authStep === 2) {
    return (
      <AccountAuthenticationOtp
        onAuthSuccess={onSuccess}
        sessionId={sessionId}
        onRetry={() => handleAccountNumberValidation(true)}
        loading={loading}
        setLoading={setLoading}
      />
    );
  } else {
    return null;
  }
};

export default AccountAuthentication;
