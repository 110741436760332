export const cardDetails = [
  {
    type: "FLEX YOUTH SAVING TIER 3",
    balance: "N300,000",
    limit: "N300,000",
    cardNumber: "XXXX XXXX XXXX 3579",
    accountName: "Tosin Faulkner",
    accountNumber: "0011357716",
  },

  {
    type: "FLEX YOUTH SAVING TIER 3",
    balance: "N300,000",
    limit: "N300,000",
    cardNumber: "XXXX XXXX XXXX 3579",
    accountName: "Tosin Faulkner",
    accountNumber: "0011357716",
  },
];
