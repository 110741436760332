// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccountBvnNinNumber_container__x5fDn {
  padding: 47px 32px;
  min-width: 493px;
  position: relative;
}

.AccountBvnNinNumber_container__x5fDn > svg {
  position: absolute;
  right: 23px;
  top: 14px;
  cursor: pointer;
}

.AccountBvnNinNumber_container__x5fDn > h3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  text-align: left;
  color: var(--black);
  margin-bottom: 28px;
}

.AccountBvnNinNumber_container__x5fDn > button {
  margin-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/AccountBvnNinNumber/AccountBvnNinNumber.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  padding: 47px 32px;\n  min-width: 493px;\n  position: relative;\n}\n\n.container > svg {\n  position: absolute;\n  right: 23px;\n  top: 14px;\n  cursor: pointer;\n}\n\n.container > h3 {\n  font-family: Montserrat;\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 21.94px;\n  text-align: left;\n  color: var(--black);\n  margin-bottom: 28px;\n}\n\n.container > button {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AccountBvnNinNumber_container__x5fDn`
};
export default ___CSS_LOADER_EXPORT___;
