import CloseIcon from "../../Assets/SVGIcons/CloseIcon/CloseIcon";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import Notifications from "../Notofications/Notofications";
import classes from "./AccountBvnNinNumber.module.css";

type AccountBvnNinNumberProps = {
  header: string;
  label: string;
  inputType: string;
  inputValue: string;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  buttonText: string;
  buttonDisabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
  error?: string;
};

const AccountBvnNinNumber = ({
  header,
  label,
  inputType,
  inputValue,
  onInputChange,
  buttonText,
  buttonDisabled,
  onClose,
  onSubmit,
  loading,
  error,
}: AccountBvnNinNumberProps) => {
  return (
    <section className={classes.container}>
      <CloseIcon onClose={onClose} />

      <h3>{header}</h3>

      {error && <Notifications message={error} severity="error" />}
      <Input
        label={label}
        type={inputType}
        value={inputValue}
        onChange={onInputChange}
      />
      <Button onClick={onSubmit} disabled={buttonDisabled} loading={loading}>
        {buttonText}
      </Button>
    </section>
  );
};

export default AccountBvnNinNumber;
