import React from "react";
import Modal from "../Modal/Modal";
import classes from "./FailureModal.module.css";
import Button from "../Button/Button";

type FailureModalProps = {
  onClose: () => void;
  onRetry: () => void;
  icon: React.ReactNode;
  title: string;
  loading?: boolean;
};

const FailureModal = ({
  onClose,
  onRetry,
  icon,
  title,
  loading,
}: FailureModalProps) => {
  return (
    <Modal
      onClick={onClose}
      body={
        <div className={classes.failureContainer}>
          <button className={classes.closeButton} onClick={onClose}>
            &times;
          </button>
          <div className={classes.iconContainer}>{icon}</div>
          <h4 className={classes.title}>{title}</h4>
          <div className={classes.buttons}>
            <Button onClick={onRetry} loading={loading}>
              Retry
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default FailureModal;
