import CardRequestRoundIcon from "../../Assets/SVGIcons/CardRequestIcon/CardRequestRoundIcon";
import CloseIcon from "../../Assets/SVGIcons/CloseIcon/CloseIcon";
import classes from "./AccountCardRequest.module.css";

type AccountCardRequestTypes = {
  onClose: () => void;
};

const AccountCardRequest = ({ onClose }: AccountCardRequestTypes) => {
  return (
    <section className={classes.container}>
      <CloseIcon onClose={onClose} />
      <h3>Card Request</h3>

      <div className={classes.accountNameSection}>
        <h4>Account Name</h4>
        <p>Tosin Faulkner</p>
      </div>

      <div className={classes.cardIcon}>
        <CardRequestRoundIcon />
      </div>

      <p>There is no card available for this account</p>
      <p>Request for a card at any of our FCMB branches</p>
    </section>
  );
};

export default AccountCardRequest;
