import SuccessIcon from "../../Assets/SVGIcons/SuccessIcon/SuccessIcon";
import SuccessModal from "../../Components/SuccessModal/SuccessModal";

type SuccessModalTypes = {
  onClose: () => void;
};

const UpgradeMyAccountSuccess = ({ onClose }: SuccessModalTypes) => {
  const successIcon = <SuccessIcon />;

  return (
    <div>
      <SuccessModal
        onClose={onClose}
        icon={successIcon}
        title="Account upgraded successfully to Tier 2"
      />
    </div>
  );
};

export default UpgradeMyAccountSuccess;
