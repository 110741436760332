import { useState } from "react";
import AccountAuthentication from "../AccountAuthentication/AccountAuthentication";
import UssdTransactions from "../UssdTransactions/UssdTransactions";

type StatusOfBillPaymentTypes = {
  onClose: () => void;
};

const StatusOfBillPayment = ({ onClose }: StatusOfBillPaymentTypes) => {
  // States
  const [step, setStep] = useState(1);
  const [sessionId, setSessionId] = useState("");

  //   Utils
  const onSuccess = () => {
    setStep((prevState) => prevState + 1);
  };

  if (step === 1) {
    return (
      <AccountAuthentication
        header="Status Of Bill Payment"
        onClose={onClose}
        onSuccess={onSuccess}
        sessionId={sessionId}
        setSessionId={setSessionId}
      />
    );
  } else if (step === 2) {
    return (
      <UssdTransactions
        onSuccess={onSuccess}
        onClose={onClose}
        sessionId={sessionId}
      />
    );
  } else if (step === 3) {
    return (
      <UssdTransactions
        onSuccess={onSuccess}
        onClose={onClose}
        sessionId={sessionId}
      />
    );
  } else {
    return null;
  }
};

export default StatusOfBillPayment;
