// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeatureCard_container__KJRiW {
  width: 100%;
  padding: 30px 16px;
  border-width: 1.5px 0.5px 0.5px 0.5px;
  border-style: solid;
  border-color: var(--purple);
  box-shadow: var(--boxShadow);
  display: flex;
  align-items: center;
  gap: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.FeatureCard_container__KJRiW:hover {
  transform: scale(1.01);
}

.FeatureCard_icon__XORRV {
  width: 40px;
  height: 40px;
  padding: 1.67px 2.5px 1.67px 2.5px;
  border-radius: 6.67px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    linear-gradient(0deg, #5c068c, #5c068c);
  display: flex;
  align-items: center;
  justify-content: center;
}

.FeatureCard_textSection__-3UIP > h4 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  margin-bottom: 12px;
  color: var(--black);
}

.FeatureCard_textSection__-3UIP > p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 21.7px;
  text-align: left;
  color: var(--black);
}
`, "",{"version":3,"sources":["webpack://./src/Components/FeatureCard/FeatureCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,qCAAqC;EACrC,mBAAmB;EACnB,2BAA2B;EAC3B,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kCAAkC;EAClC,qBAAqB;EACrB;;;;;2CAKyC;EACzC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".container {\n  width: 100%;\n  padding: 30px 16px;\n  border-width: 1.5px 0.5px 0.5px 0.5px;\n  border-style: solid;\n  border-color: var(--purple);\n  box-shadow: var(--boxShadow);\n  display: flex;\n  align-items: center;\n  gap: 18px;\n  border-radius: 8px;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n\n.container:hover {\n  transform: scale(1.01);\n}\n\n.icon {\n  width: 40px;\n  height: 40px;\n  padding: 1.67px 2.5px 1.67px 2.5px;\n  border-radius: 6.67px;\n  background: linear-gradient(\n      0deg,\n      rgba(255, 255, 255, 0.9),\n      rgba(255, 255, 255, 0.9)\n    ),\n    linear-gradient(0deg, #5c068c, #5c068c);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.textSection > h4 {\n  font-family: Montserrat;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 19.5px;\n  text-align: left;\n  margin-bottom: 12px;\n  color: var(--black);\n}\n\n.textSection > p {\n  font-family: Montserrat;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 21.7px;\n  text-align: left;\n  color: var(--black);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FeatureCard_container__KJRiW`,
	"icon": `FeatureCard_icon__XORRV`,
	"textSection": `FeatureCard_textSection__-3UIP`
};
export default ___CSS_LOADER_EXPORT___;
