import { Dispatch, SetStateAction } from "react";
import classes from "./TransactionsList.module.css";
import CloseIcon from "../../Assets/SVGIcons/CloseIcon/CloseIcon";
import { selectedDatesType, transactionType } from "../../Types";
import Loader from "../Loader/Loader";
import { months } from "../../Utilities/dateUtils";
import { transactionStatusHandler } from "../../HelperFunctions/transactionStatusHandler";
import Notifications from "../Notofications/Notofications";
import { getTodaysDate } from "../../HelperFunctions/getTodaysDate";
import { formatAmount } from "../../HelperFunctions/formatAmount";
import DropdownWithSearch from "../DropdownWithSearch/DropdownWithSearch";
import Button from "../Button/Button";

type TransactionsListProps = {
  title: string;
  accountName: string;
  transactions?: transactionType[];
  onClose: () => void;
  onSuccess: () => void;
  selectedDates?: selectedDatesType;
  setSelectedDates?: Dispatch<SetStateAction<selectedDatesType>>;
  loading?: boolean;
  setActiveTransaction?: Dispatch<SetStateAction<transactionType | null>>;
  error?: string;
  paymentMethodFilter?: string;
  setPaymentMethodFilter?: Dispatch<SetStateAction<string>>;
  getTransactions?: () => void;
};

const TransactionsList = ({
  title,
  accountName,
  transactions,
  onClose,
  onSuccess,
  selectedDates,
  setSelectedDates,
  loading,
  setActiveTransaction,
  error,
  paymentMethodFilter,
  setPaymentMethodFilter,
  getTransactions,
}: TransactionsListProps) => {
  return (
    <section className={classes.container}>
      <CloseIcon onClose={onClose} />

      <h3>{title}</h3>
      {error && <Notifications severity="error" message={error} />}

      <div className={classes.accountNameSection}>
        <h4>Account Name</h4>
        <p>{accountName}</p>
      </div>

      <div className={classes.dateRanger}>Date </div>
      <div className={classes.dateRange}>
        <div className={classes.dateInputs}>
          <div className={classes.dateInput}>
            <label>Select a date</label>
            <input
              type="date"
              value={selectedDates?.startDate}
              onChange={(e) => {
                if (setSelectedDates) {
                  setSelectedDates((prevState) => {
                    return { ...prevState, startDate: e.target.value };
                  });
                }
              }}
              placeholder="mm/dd/yyyy"
              max={getTodaysDate()}
            />
          </div>

          <Button
            onClick={getTransactions}
            loading={loading}
            disabled={!selectedDates?.startDate}
          >
            Search
          </Button>

          <DropdownWithSearch
            title="All"
            options={[
              "All",
              // "USSD", "Mobile Payment", "RIB Payment"
            ]}
            selected={paymentMethodFilter}
            setSelected={setPaymentMethodFilter}
          />
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className={classes.transactionList}>
          {transactions &&
            transactions.map((transaction, index) => {
              const date = transaction?.postedDate?.split(" ")[0].split("/");
              const day = date[0];
              const month = months[Number(date[1]) - 1];

              return (
                <div
                  onClick={() => {
                    if (setActiveTransaction) {
                      setActiveTransaction(transaction);
                      onSuccess();
                    }
                  }}
                  key={index}
                  className={classes.transactionItem}
                >
                  <div className={classes.transactionDate}>
                    <span className={classes.dateCircle}>
                      <span>{month}</span>
                      <span>{day}</span>
                    </span>
                  </div>
                  <div className={classes.transactionDetails}>
                    <h3 className={classes.amount}>
                      NGN {formatAmount(transaction?.amount)}
                    </h3>
                    <p className={classes.requestId}>{transaction.requestId}</p>
                    <p className={classes.label}>Request ID</p>
                  </div>
                  <div
                    className={`${classes.status} ${
                      classes[
                        transaction?.status === "S"
                          ? "green"
                          : transaction?.status === "N"
                          ? "yellow"
                          : "purple"
                      ]
                    }`}
                  >
                    {transactionStatusHandler(
                      transaction?.status,
                      transaction?.reverseFlag
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </section>
  );
};

export default TransactionsList;
