// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloseIcon_svg__K\\+nFK {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/SVGIcons/CloseIcon/CloseIcon.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,eAAe;AACjB","sourcesContent":[".svg {\n  position: absolute;\n  right: 20px;\n  top: 15px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg": `CloseIcon_svg__K+nFK`
};
export default ___CSS_LOADER_EXPORT___;
