export const transactionStatusHandler = (
  statusFlag: string,
  reverseFlag: string
) => {
  const status =
    statusFlag === "S"
      ? "Successful"
      : statusFlag !== "S"
      ? "Failed"
      : "Pending";

  return status;
};
