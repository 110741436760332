const EyeIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_39089_5106)">
        <path
          d="M6.34792 2.97327C6.74944 2.87928 7.16054 2.8323 7.57292 2.83327C11.6563 2.83327 13.9896 7.49993 13.9896 7.49993C13.6355 8.16237 13.2132 8.78603 12.7296 9.36077M8.80958 8.7366C8.64937 8.90854 8.45617 9.04644 8.24151 9.14209C8.02684 9.23774 7.79511 9.28917 7.56014 9.29331C7.32516 9.29746 7.09176 9.25424 6.87386 9.16622C6.65595 9.0782 6.458 8.9472 6.29183 8.78102C6.12565 8.61485 5.99465 8.4169 5.90663 8.19899C5.81861 7.98109 5.77539 7.74769 5.77954 7.51271C5.78368 7.27774 5.83511 7.04601 5.93076 6.83134C6.02641 6.61668 6.16431 6.42348 6.33625 6.26327M11.0379 10.9649C10.0408 11.725 8.82656 12.1461 7.57292 12.1666C3.48958 12.1666 1.15625 7.49993 1.15625 7.49993C1.88185 6.14771 2.88825 4.96629 4.10792 4.03493L11.0379 10.9649Z"
          stroke="#FAFAFA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.15625 1.08325L13.9896 13.9166"
          stroke="#FAFAFA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_39089_5106">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.572754 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIcon;
