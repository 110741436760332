// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccountAuthenticationOtp_container__u5w4h {
  padding: 2rem;
  width: 654px;
  min-height: 421px;
  box-sizing: border-box;
}

.AccountAuthenticationOtp_container__u5w4h > h3 {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.88px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--blackPurple);
  margin-bottom: 25px;
}

.AccountAuthenticationOtp_message__9muAe {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  text-align: center;
  color: var(--grey3);
  margin-bottom: 25px;
}

.AccountAuthenticationOtp_container__u5w4h > button {
  margin-block: 25px;
  width: 100%;
}

.AccountAuthenticationOtp_container__u5w4h > p:last-child {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: center;
}

.AccountAuthenticationOtp_container__u5w4h > p:last-child {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: center;
  color: var(--black);
}

.AccountAuthenticationOtp_container__u5w4h > p:last-child > span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: center;
  color: var(--purple);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Containers/AccountAuthenticationOtp/AccountAuthenticationOtp.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;AACjB","sourcesContent":[".container {\n  padding: 2rem;\n  width: 654px;\n  min-height: 421px;\n  box-sizing: border-box;\n}\n\n.container > h3 {\n  font-family: Montserrat;\n  font-size: 36px;\n  font-weight: 600;\n  line-height: 43.88px;\n  letter-spacing: 0.02em;\n  text-align: center;\n  color: var(--blackPurple);\n  margin-bottom: 25px;\n}\n\n.message {\n  font-family: Montserrat;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24.8px;\n  text-align: center;\n  color: var(--grey3);\n  margin-bottom: 25px;\n}\n\n.container > button {\n  margin-block: 25px;\n  width: 100%;\n}\n\n.container > p:last-child {\n  font-family: Montserrat;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 19.5px;\n  text-align: center;\n}\n\n.container > p:last-child {\n  font-family: Montserrat;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 19.5px;\n  text-align: center;\n  color: var(--black);\n}\n\n.container > p:last-child > span {\n  font-family: Montserrat;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 19.5px;\n  text-align: center;\n  color: var(--purple);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AccountAuthenticationOtp_container__u5w4h`,
	"message": `AccountAuthenticationOtp_message__9muAe`
};
export default ___CSS_LOADER_EXPORT___;
