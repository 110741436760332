import { Dispatch, SetStateAction } from "react";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import classes from "./AccountAuthenticationAccountNumber.module.module.css";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";
import CloseIcon from "../../Assets/SVGIcons/CloseIcon/CloseIcon";
import Notifications from "../../Components/Notofications/Notofications";

type AccountAuthenticationAccountNumberTypes = {
  header: string;
  onClose: () => void;
  accountNumber: string;
  setAccountNumber: Dispatch<SetStateAction<string>>;
  onClick: () => void;
  loading: boolean;
  error: string;
};

const AccountAuthenticationAccountNumber = ({
  header,
  onClose,
  accountNumber,
  setAccountNumber,
  onClick,
  loading,
  error,
}: AccountAuthenticationAccountNumberTypes) => {
  return (
    <section className={classes.container}>
      <CloseIcon onClose={onClose} />

      <h3>{header}</h3>

      {error && <Notifications message={error} severity="error" />}

      <Input
        label="Enter Account Number"
        type="number"
        value={accountNumber}
        onChange={(e) => inputChangeHandler(e, setAccountNumber, true)}
        max="10"
      />
      <Button
        onClick={onClick}
        disabled={!accountNumber || accountNumber.length !== 10}
        loading={loading}
      >
        Next
      </Button>
    </section>
  );
};

export default AccountAuthenticationAccountNumber;
