import classes from "./Notofications.module.css";

type NotificationsType = {
  message: string;
  severity: "success" | "error";
};

const Notifications = ({ message, severity }: NotificationsType) => {
  return (
    <div
      className={`${classes.container} ${
        severity === "success" ? classes.success : classes.error
      }`}
    >
      <span>{message}</span>
    </div>
  );
};

export default Notifications;
