// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage_heroSection__jcrhk {
  padding: 0px 95px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90.14deg, #f8dff5 0.21%, #e2daf3 94.74%);
}

.HomePage_heroSection__jcrhk > div > h1 {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.76px;
  text-align: left;
  color: var(--border-purple);
  margin-bottom: 12px;
}

.HomePage_heroSection__jcrhk > div > h3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  text-align: left;
  color: var(--grey);
}

.HomePage_heroSection__jcrhk > img {
  width: 344.13px;
  height: 380.08px;
  margin-right: 141px;
}

.HomePage_featureSection__lPe\\+1 {
  padding: 51px 90px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.HomePage_featureSection__lPe\\+1 > div {
  flex-basis: calc(25% - 20px);
}
`, "",{"version":3,"sources":["webpack://./src/Containers/HomePage/HomePage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,oEAAoE;AACtE;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;EAChB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,oBAAoB;EACpB,2BAA2B;EAC3B,eAAe;EACf,SAAS;AACX;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".heroSection {\n  padding: 0px 95px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: linear-gradient(90.14deg, #f8dff5 0.21%, #e2daf3 94.74%);\n}\n\n.heroSection > div > h1 {\n  font-family: Montserrat;\n  font-size: 40px;\n  font-weight: 600;\n  line-height: 48.76px;\n  text-align: left;\n  color: var(--border-purple);\n  margin-bottom: 12px;\n}\n\n.heroSection > div > h3 {\n  font-family: Montserrat;\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 21.94px;\n  text-align: left;\n  color: var(--grey);\n}\n\n.heroSection > img {\n  width: 344.13px;\n  height: 380.08px;\n  margin-right: 141px;\n}\n\n.featureSection {\n  padding: 51px 90px;\n  display: flex;\n  align-items: stretch;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  gap: 20px;\n}\n\n.featureSection > div {\n  flex-basis: calc(25% - 20px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroSection": `HomePage_heroSection__jcrhk`,
	"featureSection": `HomePage_featureSection__lPe+1`
};
export default ___CSS_LOADER_EXPORT___;
