import classes from "./UserCard.module.css";
import fcmbLogo from "../../Assets/Images/fcmbLogo.svg";
import EyeIcon from "../../Assets/SVGIcons/EyeIcon/EyeIcon";

const UserCard = () => {
  return (
    <div className={classes.container}>
      <div className={classes.headerSection}>
        <img src={fcmbLogo} alt="FCMB Logo" />
        <p>FLEX YOUTH SAVING TIER 3</p>
      </div>

      <div className={classes.amountsSection}>
        <div>
          <p>CARD BALANCE</p>
          <p>N300,000</p>
        </div>

        <div>
          <p>CARD LIMIT</p>
          <p>N300,000</p>
        </div>
      </div>

      <div className={classes.cardNumber}>
        <p>XXXX XXXX XXXX 3579</p>
        <EyeIcon />
      </div>

      <div className={classes.amountsSection}>
        <div>
          <p>A/C NAME</p>
          <p>Tosin Faulkner</p>
        </div>

        <div>
          <p>A/C NUMBERt</p>
          <p>0011357716</p>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
