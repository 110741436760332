import classes from "./Header.module.css";
import fcmbLogo from "../../Assets/Images/fcmbLogoSmall.svg";
import Button from "../../Components/Button/Button";
import { useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import { capitalizeEachWord } from "../../HelperFunctions/capitalize";

const Header = () => {
  // Context
  const { user, setUser } = useContext(UserContext);

  return (
    <header className={classes.container}>
      <img src={fcmbLogo} alt="First City Monument Bank" />
      <h1>Self Service Portal</h1>

      {user && (
        <div className={classes.userSection}>
          <p>
            <span>Welcome</span>, {capitalizeEachWord(user?.accountName)}
          </p>
          <Button onClick={() => setUser(null)}>Logout</Button>
        </div>
      )}
    </header>
  );
};

export default Header;
