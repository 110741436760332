import { useContext } from "react";
import TransactionDetails from "../../Components/TransactionDetails/TransactionDetails";
import { transactionType } from "../../Types";
import { UserContext } from "../../Context/UserContext";
import { capitalizeEachWord } from "../../HelperFunctions/capitalize";
import { transactionStatusHandler } from "../../HelperFunctions/transactionStatusHandler";
import { formatAmount } from "../../HelperFunctions/formatAmount";

type UssdTransactionDetailsTypes = {
  onClose: () => void;
  onSuccess: () => void;
  data: transactionType | null;
  paymentMethodFilter?: string;
};

const UssdTransactionDetails = ({
  onClose,
  data,
  paymentMethodFilter,
}: UssdTransactionDetailsTypes) => {
  // Context
  const { user } = useContext(UserContext);

  return (
    <TransactionDetails
      buttonLabel={`Back to ${paymentMethodFilter} Transactions`}
      accountName={capitalizeEachWord(user?.accountName as string)}
      requestId={data?.requestId as string}
      destinationBank={data?.beneficiaryBankName as string}
      senderAccountNumber={data?.sourceAccountNumber as string}
      beneficiaryAccountName={capitalizeEachWord(
        data?.beneficiaryAccountName as string
      )}
      amount={`NGN ${formatAmount(data?.amount as number)}`}
      narration={data?.narration as string}
      status={transactionStatusHandler(
        data?.status as string,
        data?.reverseFlag as string
      )}
      response="Response"
      channel={data?.channel as string}
      onClose={onClose}
    />
  );
};

export default UssdTransactionDetails;
