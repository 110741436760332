const BlockMyAccountIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4483 10.092C13.2352 10.092 15.4943 7.83283 15.4943 5.046C15.4943 2.25917 13.2352 0 10.4483 0C7.66151 0 5.40234 2.25917 5.40234 5.046C5.40234 7.83283 7.66151 10.092 10.4483 10.092Z"
        fill="#5F138D"
      />
      <path
        d="M12.5 19C12.5 15.7925 14.8255 13.134 17.8815 12.603C17.055 11.5395 16.0245 10.656 14.8515 10.021C13.677 11.061 12.139 11.697 10.4485 11.697C8.76549 11.697 7.23299 11.0665 6.06149 10.0365C3.00499 11.7045 0.916992 15.051 0.916992 18.909C0.916992 19.5165 0.971492 20.1095 1.07049 20.6875H12.729C12.5845 20.1485 12.5 19.585 12.5 19Z"
        fill="#5F138D"
      />
      <path
        d="M19 14C16.239 14 14 16.239 14 19C14 21.761 16.239 24 19 24C21.761 24 24 21.761 24 19C24 16.239 21.761 14 19 14ZM21.8395 20.779L20.779 21.8395L19 20.0605L17.221 21.84L16.1605 20.779L17.9395 19L16.1605 17.221L17.221 16.1605L19 17.9395L20.779 16.1605L21.8395 17.2215L20.0605 19L21.8395 20.779Z"
        fill="#5F138D"
      />
    </svg>
  );
};

export default BlockMyAccountIcon;
