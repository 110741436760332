import React from "react";
import classes from "./Button.module.css";
import CircularProgress from "../../Assets/SVGIcons/CircularProgress/CircularProgress";

type ButtonPropTypes = {
  children: React.ReactNode;
  type?: "primary" | "secondary";
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
};

const Button = ({
  children,
  type,
  disabled,
  onClick,
  loading,
}: ButtonPropTypes) => {
  return (
    <button
      className={`${classes.button} ${
        type === "secondary" ? classes.secondary : classes.primary
      }`}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {loading ? (
        <CircularProgress height="24px" width="24px" color="#ffffff" />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
