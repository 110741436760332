import { useState } from "react";
import AccountAuthentication from "../AccountAuthentication/AccountAuthentication";
import ViewCards from "../ViewCards/ViewCards";
import AccountAuthenticationOtp from "../AccountAuthenticationOtp/AccountAuthenticationOtp";
import SuccessModal from "../../Components/SuccessModal/SuccessModal";
import SuccessIcon from "../../Assets/SVGIcons/SuccessIcon/SuccessIcon";

type BlockMyCardTypes = {
  onClose: () => void;
};

const BlockMyCard = ({ onClose }: BlockMyCardTypes) => {
  // States
  const [step, setStep] = useState(1);

  // Utils
  const updateStep = () => {
    setStep((prevState) => prevState + 1);
  };

  if (step === 1) {
    return (
      <AccountAuthentication
        header="Block My Card"
        onClose={onClose}
        onSuccess={updateStep}
      />
    );
  } else if (step === 2) {
    return <ViewCards onClose={onClose} onSuccess={updateStep} />;
  } else if (step === 3) {
    return <AccountAuthenticationOtp onAuthSuccess={updateStep} />;
  } else if (step === 4) {
    return (
      <SuccessModal
        onClose={onClose}
        icon={<SuccessIcon />}
        title="Card blocked successfully"
      />
    );
  } else return null;
};

export default BlockMyCard;
