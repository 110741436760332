import CircularProgress from "../../Assets/SVGIcons/CircularProgress/CircularProgress";
import React, { useState, useRef, useEffect, useMemo } from "react";
import classes from "./DropdownWithSearch.module.css";
import DropdownIcon from "../../Assets/SVGIcons/DropdownIcon/DropdownIcon";

export type DropdownProps = {
  title: string | React.ReactNode;
  options: string[] | undefined;
  selected?: string | undefined | any;
  setSelected?: React.Dispatch<React.SetStateAction<string | undefined | any>>;
  isLoading?: boolean;
  label?: string;
  isRequired?: boolean;
  errorMessage?: string;
  image?: string;
  tip?: string;
};

const DropdownWithSearch = (props: DropdownProps) => {
  const [isActive, setIsActive] = useState(false);
  const [keyPressedValue, setKEyPressedValue] = useState("");
  const [invalid, setInvalid] = useState(false);

  // ref
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownItem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const removeDropdownHandler = (e: any) => {
      if (dropdownRef && !dropdownRef?.current?.contains(e.target)) {
        setIsActive(false);
      } else {
      }
    };
    document.addEventListener("mousedown", removeDropdownHandler);

    return () => {
      document.removeEventListener("mousedown", removeDropdownHandler);
    };
  }, []);

  // Memos
  const filteredOptions = useMemo(
    () =>
      props.options?.filter((data) =>
        data?.toLowerCase()?.includes(keyPressedValue?.toLowerCase())
      ),
    [keyPressedValue, props.options]
  );

  useEffect(() => {
    const removeDropdownHandler = (e: any) => {
      if (dropdownRef && !dropdownRef?.current?.contains(e.target)) {
        setIsActive(false);
      } else {
      }
    };
    document.addEventListener("mousedown", removeDropdownHandler);

    return () => {
      document.removeEventListener("mousedown", removeDropdownHandler);
    };
  }, [props.selected, props.isRequired]);

  return (
    <div className={classes.container}>
      {props?.label && (
        <label htmlFor={props.label} className={classes.label}>
          {props.label} {props.isRequired && <span>*</span>}
        </label>
      )}

      <div
        className={`${classes.dropdown} ${
          invalid ? classes.invalid : classes.valid
        } ${isActive ? classes.active : classes.inActive}`}
        ref={dropdownRef}
      >
        <div
          className={classes.dropdownButton}
          onClick={() => {
            setIsActive(!isActive);
          }}
          onBlur={() => {
            if (props.isRequired && !props?.selected && !isActive) {
              setInvalid(true);
            } else {
              setInvalid(false);
            }
          }}
          tabIndex={0}
          onKeyDown={(event) => {
            if (
              event.code === `Key${event.key.toUpperCase()}` &&
              props?.options &&
              props.options?.length > 8
            ) {
              setKEyPressedValue(event.key);
            }
          }}
        >
          {props?.selected || props?.title}
          <DropdownIcon isActive={isActive} />
        </div>
        {isActive && (
          <div className={classes.dropdownContent}>
            {props.options && props.options?.length > 8 && (
              <div className={classes.inputSection}>
                <input
                  type="text"
                  placeholder="Search"
                  value={keyPressedValue}
                  onChange={(e) => {
                    if (props.options && props.options?.length > 8) {
                      setKEyPressedValue(e.target.value);
                    }
                  }}
                />
              </div>
            )}
            {filteredOptions && filteredOptions?.length > 0 ? (
              filteredOptions?.map((option, i) => {
                return (
                  <div
                    key={i}
                    className={classes.dropdownItem}
                    onClick={() => {
                      if (props.setSelected) props?.setSelected(option);
                      setIsActive(false);
                    }}
                    ref={dropdownItem}
                  >
                    {option}
                  </div>
                );
              })
            ) : !props.isLoading &&
              props.options &&
              props.options.length === 0 ? (
              <p className={`${classes.dropdownItem2}`}>No matching Items</p>
            ) : (
              <div className={classes.loadingContainer}>
                <CircularProgress width="1rem" height="1rem" color="inherit" />
              </div>
            )}
          </div>
        )}
      </div>
      {props.tip && <span className={classes.tip}>{props.tip}</span>}
      {invalid && (
        <div className={classes.errorMessage}>
          {props.errorMessage || "Please choose an option to continue"}
        </div>
      )}
    </div>
  );
};

export default DropdownWithSearch;
