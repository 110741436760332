import axiosInstance from ".";
import {
  blockAcccountRequestBody,
  checkTransferStatusRequestBody,
  upgradeAcccountRequestBody,
  userType,
  validateAccountNumberOtpRequestBody,
  validateAccountNumberRequestBody,
  validateBvnRequestBody,
  validateNinRequestBody,
} from "../Types";
import { Response } from "../Types";

export const validateAccountNumber = (
  data: validateAccountNumberRequestBody
) => {
  return axiosInstance.post<Response<userType>>(
    `/AccountService/validate-account`,
    data
  );
};

export const validateAccountNumberOtp = (
  data: validateAccountNumberOtpRequestBody
) => {
  return axiosInstance.post<Response<any>>(`/OtpService/verify-otp`, data);
};

export const blockAccount = (data: blockAcccountRequestBody) => {
  return axiosInstance.post<Response<any>>(
    `/AccountService/block-account`,
    data
  );
};

export const upgradeAccount = (data: upgradeAcccountRequestBody) => {
  return axiosInstance.post<Response<any>>(
    `/AccountService/upgrade-account`,
    data
  );
};
export const validateNin = (data: validateNinRequestBody) => {
  return axiosInstance.post<Response<any>>(
    `/AccountService/validate-nin`,
    data
  );
};
export const validateBvn = (data: validateBvnRequestBody) => {
  return axiosInstance.post<Response<any>>(
    `/AccountService/validate-bvn`,
    data
  );
};

export const checkTransferStatus = (data: checkTransferStatusRequestBody) => {
  return axiosInstance.post<Response<any>>(
    `/AccountService/nip-transfer-status`,
    data
  );
};
