import React, { useState } from "react";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";
import AccountBvnNinNumber from "../../Components/AccountBvnNinNumber/AccountBvnNinNumber";
import { validateBvn } from "../../Services/services";
import { generateId } from "../../HelperFunctions/generateId";

type AccountBvnAuthenticationTypes = {
  onClose: () => void;
  onSuccess: () => void;
  sessionId: string;
  altLoading: boolean;
};
const AccountBvnAuthentication = ({
  onClose,
  onSuccess,
  sessionId,
  altLoading,
}: AccountBvnAuthenticationTypes) => {
  const [bvnNumber, setBvnNumber] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Utils
  const handleBvnValidation = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await validateBvn({
        requestId: generateId(),
        sessionId,
        bvn: bvnNumber,
      });

      if (response?.data) {
        onSuccess();
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputChangeHandler(e, setBvnNumber, true);
  };

  return (
    <AccountBvnNinNumber
      header="Account Upgrade"
      label="Enter BVN Number"
      inputType="number"
      inputValue={bvnNumber}
      onInputChange={handleInputChange}
      buttonText="Next"
      buttonDisabled={!bvnNumber || bvnNumber.length < 11}
      onClose={onClose}
      onSubmit={handleBvnValidation}
      loading={loading || altLoading}
      error={error}
    />
  );
};

export default AccountBvnAuthentication;
