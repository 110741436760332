import classes from "./FeatureCard.module.css";

type FeatureCardTypes = {
  icon: React.ReactNode;
  title: string;
  caption: string;
  onClick: () => void;
};

const FeatureCard = ({ icon, title, caption, onClick }: FeatureCardTypes) => {
  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.icon}>{icon}</div>
      <div className={classes.textSection}>
        <h4>{title}</h4>
        <p>{caption}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
