const AccountUpgradeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.001 7.05016C12.5532 7.05016 13.001 6.60246 13.001 6.05017C13.001 5.49788 12.5532 5.05017 12.001 5.05017C11.4487 5.05017 11.001 5.49788 11.001 6.05017C11.001 6.60246 11.4487 7.05016 12.001 7.05016Z"
        fill="#5C068C"
      />
      <path
        d="M19.2 16.0088V9.60008H19.3116C20.3836 9.60008 20.8224 8.22317 19.9481 7.60292L12.9262 2.62122C12.3718 2.22782 11.6292 2.22782 11.0747 2.62121L4.05253 7.60291C3.17825 8.22316 3.61707 9.60008 4.68901 9.60008H4.80009V16.0088C4.07843 16.4708 3.60009 17.2796 3.60009 18.2V19.8001C3.60009 20.1314 3.86872 20.4001 4.20009 20.4001H19.8C20.1315 20.4001 20.4 20.1314 20.4 19.8001V18.2C20.4 17.2796 19.9217 16.4708 19.2 16.0088ZM11.769 3.59993C11.9076 3.50159 12.0933 3.50159 12.2319 3.59994L18.9978 8.40008H15.0026L15 8.40007L14.9975 8.40008H5.00278L11.769 3.59993ZM18 9.60008V15.6077C17.934 15.6026 17.8674 15.6001 17.8001 15.6001H15.6V9.60008H18ZM6.00009 15.6077V9.60008H8.40009V15.6001H6.20009C6.1328 15.6001 6.0661 15.6026 6.00009 15.6077ZM6.20009 16.8001H17.8001C18.5733 16.8001 19.2 17.4269 19.2 18.2V19.2001H4.80009V18.2C4.80009 17.4269 5.4269 16.8001 6.20009 16.8001ZM11.4001 15.6001H9.60009V9.60008H11.4001V15.6001ZM12.6 15.6001V9.60008H14.4V15.6001H12.6Z"
        fill="#5C068C"
      />
    </svg>
  );
};

export default AccountUpgradeIcon;
