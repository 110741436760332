const CardRequestSquareIcon = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 1.66675H2.66667C1.19375 1.66675 0 2.86091 0 4.33341V5.66675H24V4.33341C24 2.86091 22.8042 1.66675 21.3333 1.66675ZM0 17.6667C0 19.1392 1.19375 20.3334 2.66667 20.3334H21.3333C22.8063 20.3334 24 19.1392 24 17.6667V9.66675H0V17.6667ZM7.9625 15.3334C7.9625 15.1492 8.11175 15.0001 8.29583 15.0001H14.2583C14.4424 15.0001 14.5917 15.1492 14.5917 15.3334L14.6667 16.0001C14.6667 16.1842 14.5174 16.3334 14.3333 16.3334H8.33333C8.15 16.3334 8 16.1834 8 16.0001L7.9625 15.3334ZM2.62917 15.3334C2.62917 15.1492 2.77842 15.0001 2.9625 15.0001H6.29583C6.47992 15.0001 6.62917 15.1492 6.62917 15.3334L6.66667 16.0001C6.66667 16.1834 6.51667 16.3334 6.33333 16.3334H3C2.81583 16.3334 2.66667 16.1834 2.66667 16.0001L2.62917 15.3334Z"
        fill="#5C068C"
      />
    </svg>
  );
};

export default CardRequestSquareIcon;
