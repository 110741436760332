import SuccessIcon from "../../Assets/SVGIcons/SuccessIcon/SuccessIcon";
import SuccessModal from "../../Components/SuccessModal/SuccessModal";

type SuccessModalTypes = {
  onClose: () => void;
};

const BlockMyAccountSuccess = ({ onClose }: SuccessModalTypes) => {
  const successIcon = <SuccessIcon />;

  return (
    <div>
      <SuccessModal
        onClose={onClose}
        icon={successIcon}
        title="Account Blocked Successfully"
        caption="NB: To unblock your account kindly visit any FCMB branch."
      />
    </div>
  );
};

export default BlockMyAccountSuccess;
