import { useState } from "react";
import AccountAuthentication from "../AccountAuthentication/AccountAuthentication";
import AccountUpgradeAccount from "../AccountUpgradeAccount/AccountUpgradeAccount";
import AccountBvnAuthentication from "../AccountBvnAuthentication/AccountBvnAuthentication";
import AccountNinAuthentication from "../AccountNinAuthentication/AccountNinAuthentication";
import UpgradeMyAccountSuccess from "../UpgradeMyAccountSuccess/UpgradeMyAccountSuccess";
import { upgradeAccount } from "../../Services/services";
import { generateId } from "../../HelperFunctions/generateId";
import UpgradeMyAccountFailure from "../UpgradeMyAccountFailure/BlockMyAccountFailure";

type AccountUpgradeTypes = {
  onClose: () => void;
  onRetry: () => void;
  onAuthSuccess: () => void;
};

const AccountUpgrade = ({ onClose }: AccountUpgradeTypes) => {
  // States
  const [step, setStep] = useState(1);
  const [sessionId, setSessionId] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  //   Utils
  const onSuccess = () => {
    setStep((prevState) => prevState + 1);
  };

  const handleUpgradeAccount = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await upgradeAccount({
        requestId: generateId(),
        sessionId,
      });

      if (response.data) {
        setStep(5);
      }
    } catch (error: any) {
      setError(error?.data?.response?.message || error?.message);

      setStep(6);
    } finally {
      setLoading(false);
    }
  };

  if (step === 1) {
    return (
      <AccountAuthentication
        header="Account Upgrade"
        onClose={onClose}
        onSuccess={onSuccess}
        sessionId={sessionId}
        setSessionId={setSessionId}
      />
    );
  } else if (step === 2) {
    return (
      <AccountUpgradeAccount
        onClose={onClose}
        onSuccess={onSuccess}
        secondaryError={error}
      />
    );
  } else if (step === 3) {
    return (
      <AccountNinAuthentication
        onClose={onClose}
        onSuccess={onSuccess}
        sessionId={sessionId}
      />
    );
  } else if (step === 4) {
    return (
      <AccountBvnAuthentication
        onClose={onClose}
        onSuccess={handleUpgradeAccount}
        sessionId={sessionId}
        altLoading={loading}
      />
    );
  } else if (step === 5) {
    return <UpgradeMyAccountSuccess onClose={onClose} />;
  } else if (step === 6) {
    return (
      <UpgradeMyAccountFailure
        onRetry={handleUpgradeAccount}
        onClose={onClose}
        loading={loading}
      />
    );
  } else {
    return null;
  }
};

export default AccountUpgrade;
