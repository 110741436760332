// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FailureModal_failureContainer__UzaAL {
  text-align: center;
  padding: 20px;
  position: relative;
  width: 493px;
  height: 302px;
}

.FailureModal_iconContainer__tlELg {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.FailureModal_title__V9BVT {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 10px;
}

.FailureModal_buttons__tQchI {
  margin-top: 20px;
}

.FailureModal_retryButton__kkwQQ {
  background-color: #d32f2f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.FailureModal_retryButton__kkwQQ:hover {
  background-color: #b71c1c;
}

.FailureModal_closeButton__UlLTh {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/Components/FailureModal/FailureModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb","sourcesContent":[".failureContainer {\n  text-align: center;\n  padding: 20px;\n  position: relative;\n  width: 493px;\n  height: 302px;\n}\n\n.iconContainer {\n  display: flex;\n  justify-content: center;\n  margin: 20px auto;\n}\n\n.title {\n  font-size: 1.5rem;\n  color: #000;\n  margin-bottom: 10px;\n}\n\n.buttons {\n  margin-top: 20px;\n}\n\n.retryButton {\n  background-color: #d32f2f;\n  color: white;\n  padding: 10px 20px;\n  border: none;\n  border-radius: 4px;\n  font-size: 1rem;\n  cursor: pointer;\n}\n\n.retryButton:hover {\n  background-color: #b71c1c;\n}\n\n.closeButton {\n  position: absolute;\n  top: 10px;\n  right: 15px;\n  background: none;\n  border: none;\n  font-size: 1.5rem;\n  cursor: pointer;\n  color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"failureContainer": `FailureModal_failureContainer__UzaAL`,
	"iconContainer": `FailureModal_iconContainer__tlELg`,
	"title": `FailureModal_title__V9BVT`,
	"buttons": `FailureModal_buttons__tQchI`,
	"retryButton": `FailureModal_retryButton__kkwQQ`,
	"closeButton": `FailureModal_closeButton__UlLTh`
};
export default ___CSS_LOADER_EXPORT___;
