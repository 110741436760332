import React from "react";
import classes from "./TransactionDetails.module.css";
import BackArrowIcon from "../../Assets/SVGIcons/CardRequestIcon/BackArrowIcon";

type TransactionDetailsProps = {
  buttonLabel: string;
  accountName: string;
  requestId: string;
  destinationBank: string;
  senderAccountNumber: string;
  beneficiaryAccountName: string;
  amount: string;
  narration: string;
  status: string;
  response: string;
  channel: string;
  onClose: () => void;
};

const TransactionDetails = ({
  buttonLabel,
  accountName,
  requestId,
  destinationBank,
  senderAccountNumber,
  beneficiaryAccountName,
  amount,
  narration,
  status,
  response,
  channel,
  onClose,
}: TransactionDetailsProps) => {
  return (
    <div className={classes.container}>
      <button className={classes.button} onClick={onClose}>
        <BackArrowIcon />
        {buttonLabel}
      </button>

      <h3>Transactions Details</h3>

      <div className={classes.accountNameSection}>
        <h4>Account Name</h4>
        <p>{accountName}</p>
      </div>

      <div className={classes.transactionDetails}>
        <div className={classes.allDetailRow}>
          <div className={classes.detailRow}>
            <p>Request ID</p>
            <span className={classes.value}>{requestId}</span>
          </div>
          <div className={classes.detailRow}>
            <p>Destination Bank</p>
            <span className={classes.value}>{destinationBank}</span>
          </div>
          <div className={classes.detailRow}>
            <p>Sender Account Number</p>
            <span className={classes.value}>{senderAccountNumber}</span>
          </div>
          <div className={classes.detailRow}>
            <p>Beneficiary Account Name</p>
            <span className={classes.value}>{beneficiaryAccountName}</span>
          </div>
          <div className={classes.detailRow}>
            <p>Amount</p>
            <span className={classes.value}>{amount}</span>
          </div>
          <div className={classes.detailRow}>
            <p>Narration</p>
            <span className={classes.value}>{narration}</span>
          </div>
          <div className={classes.detailRow}>
            <p>Status</p>
            <span className={`${classes.status} ${classes.success}`}>
              {status}
            </span>
          </div>
          <div className={classes.detailRow}>
            <p>Response</p>
            <span className={classes.value}>{response}</span>
          </div>
          <div className={classes.detailRow}>
            <p>Channel</p>
            <span className={classes.value}>{channel}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
