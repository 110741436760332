import classes from "./Loader.module.css";
import CircularProgress from "../../Assets/SVGIcons/CircularProgress/CircularProgress";

const Loader = () => {
  return (
    <div className={classes.container}>
      <CircularProgress
        color="rgba(92, 6, 140, 1)"
        width="32px"
        height="32px"
      />
    </div>
  );
};

export default Loader;
