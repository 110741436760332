type DropdownIconType = {
  isActive: boolean;
};

const DropdownIcon = ({ isActive }: DropdownIconType) => {
  return (
    <svg
      width="9"
      height="7"
      viewBox="0 0 9 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        isActive
          ? { transform: "rotate(-90deg)" }
          : { transform: "rotate(0deg)" }
      }
    >
      <path
        d="M4.27102 6.32851L0.0280151 2.08551L1.44302 0.671509L4.27102 3.50051L7.09902 0.671509L8.51402 2.08551L4.27102 6.32851Z"
        fill="#334335"
      />
      <path
        d="M4.27102 6.32851L0.0280151 2.08551L1.44302 0.671509L4.27102 3.50051L7.09902 0.671509L8.51402 2.08551L4.27102 6.32851Z"
        fill="white"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default DropdownIcon;
