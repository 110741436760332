import Layout from "../../Components/Layout/Layout";
import classes from "./HomePage.module.css";
import heroSectionImage from "../../Assets/Images/heroSectionImage.svg";
import FeatureCard from "../../Components/FeatureCard/FeatureCard";
import { features } from "../../Utilities/features";
import React, { useContext, useEffect, useState } from "react";
import Modal from "../../Components/Modal/Modal";
import CardRequest from "../CardRequest/CardRequest";
import BlockMyAccount from "../BlockMyAccount/BlockMyAccount";
import AccountUpgrade from "../AccountUpgrade/AccountUpgrade";
import StatusOfBillPayment from "../StatusOfBillPayment/StatusOfBillPayment";
import BlockMyCard from "../BlockMyCard/BlockMyCard";
import { UserContext } from "../../Context/UserContext";
import { INACTIVITY_SECONDS } from "../../Utilities/constants";

const HomePage = () => {
  // States
  const [modals, setModals] = useState<{ [key: string]: boolean }>({
    blockMyCard: false,
    cardRequest: false,
    blockMyAccount: false,
    accountUpgrade: false,
    statusOfBillPayment: false,
  });
  const [activityTimer, setActivityTimer] = useState(INACTIVITY_SECONDS);

  // COntext
  const { setUser } = useContext(UserContext);

  // Utils
  const setAllModalsFalse = () => {
    setModals((prevState) => {
      const updatedState = { ...prevState };

      Object.keys(prevState).forEach((key) => (updatedState[key] = false));

      return updatedState;
    });
  };

  const closeUserSessionIfInactive = () => {
    setUser(null);
    setAllModalsFalse();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActivityTimer((prevState) => {
        if (prevState > 0) {
          return prevState - 1;
        } else {
          return 0;
        }
      });
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };

    // eslint-disable-next-line
  });

  useEffect(() => {
    const mouseHandler = () => {
      setActivityTimer(INACTIVITY_SECONDS);
    };

    document.addEventListener("mousemove", mouseHandler);

    return () => {
      document.removeEventListener("mousemove", mouseHandler);
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activityTimer <= 0) {
      closeUserSessionIfInactive();
    }

    // eslint-disable-next-line
  }, [activityTimer]);

  return (
    <Layout>
      {modals?.cardRequest && (
        <Modal
          onClick={setAllModalsFalse}
          body={<CardRequest onClose={setAllModalsFalse} />}
        />
      )}

      {modals?.blockMyAccount && (
        <Modal
          onClick={setAllModalsFalse}
          body={
            <BlockMyAccount
              onClose={setAllModalsFalse}
              onAuthSuccess={setAllModalsFalse}
            />
          }
        />
      )}

      {modals?.accountUpgrade && (
        <Modal
          onClick={setAllModalsFalse}
          body={
            <AccountUpgrade
              onClose={setAllModalsFalse}
              onAuthSuccess={setAllModalsFalse}
              onRetry={setAllModalsFalse}
            />
          }
        />
      )}

      {modals?.statusOfBillPayment && (
        <Modal
          onClick={setAllModalsFalse}
          body={<StatusOfBillPayment onClose={setAllModalsFalse} />}
        />
      )}

      {modals?.blockMyCard && (
        <Modal
          onClick={setAllModalsFalse}
          body={<BlockMyCard onClose={setAllModalsFalse} />}
        />
      )}

      <section className={classes.container}>
        <div className={classes.heroSection}>
          <div>
            <h1>Self Service </h1>
            <h3>Banking made easy anywhere</h3>
          </div>
          <img src={heroSectionImage} alt="FCMB Self Service" />
        </div>

        <div className={classes.featureSection}>
          {features.map((feature) => {
            return (
              <React.Fragment key={feature?.title}>
                <FeatureCard
                  title={feature?.title}
                  caption={feature?.caption}
                  icon={feature.icon}
                  onClick={() => {
                    setModals((prevState) => {
                      const updatedState = { ...prevState };
                      Object.keys(updatedState)?.forEach((key) => {
                        updatedState[key] = false;
                      });

                      if (feature?.id) {
                        updatedState[feature?.id] = true;
                      }
                      return updatedState;
                    });
                  }}
                />
              </React.Fragment>
            );
          })}
        </div>
      </section>
    </Layout>
  );
};

export default HomePage;
