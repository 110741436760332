import { useState } from "react";
import AccountAuthentication from "../AccountAuthentication/AccountAuthentication";
import AccountCardRequest from "../AccountCardRequest/AccountCardRequest";

type CardRequestTypes = {
  onClose: () => void;
};

const CardRequest = ({ onClose }: CardRequestTypes) => {
  // States
  const [step, setStep] = useState(1);

  //   Utils
  const onSuccess = () => {
    setStep((prevState) => prevState + 1);
  };

  if (step === 1) {
    return (
      <AccountAuthentication
        header="Card Request"
        onClose={onClose}
        onSuccess={onSuccess}
      />
    );
  } else if (step === 2) {
    return <AccountCardRequest onClose={onClose} />;
  } else {
    return null;
  }
};

export default CardRequest;
