import { useContext, useState } from "react";
import AccountAuthentication from "../AccountAuthentication/AccountAuthentication";
import AccountBlockMyAccount from "../AccountBlockMyAccount/AccountBlockMyAccount";
import BlockMyAccountSuccess from "../BlockMyAccountSuccess/BlockMyAccountSuccess";
import AccountAuthenticationOtp from "../AccountAuthenticationOtp/AccountAuthenticationOtp";
import { blockAccount, validateAccountNumber } from "../../Services/services";
import { generateId } from "../../HelperFunctions/generateId";
import { UserContext } from "../../Context/UserContext";
import BlockMyAccountFailure from "../BlockMyAccountFailure/BlockMyAccountFailure";

type BlockMyAccountTypes = {
  onClose: () => void;
  onAuthSuccess: () => void;
};

const BlockMyAccount = ({ onClose }: BlockMyAccountTypes) => {
  // States
  const [step, setStep] = useState(1);
  const [sessionId, setSessionId] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // COntext
  const { setUser, user } = useContext(UserContext);

  //   Utils
  const onSuccess = () => {
    setStep((prevState) => prevState + 1);
  };

  const handleBlockAccount = async () => {
    setLoading(true);

    try {
      const response = await blockAccount({
        requestId: generateId(),
        sessionId,
      });

      if (response.data) {
        onSuccess();
        setError("");
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);

      setStep(5);
    } finally {
      setLoading(false);
    }
  };

  const handleAccountNumberValidation = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await validateAccountNumber({
        requestId: generateId(),
        accountNumber: user?.accountNumber as string,
      });

      if (response?.data) {
        setUser(response?.data?.data);

        if (setSessionId) {
          setSessionId(response?.data?.sessionId);
        }
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  if (step === 1) {
    return (
      <AccountAuthentication
        header="Block My Account"
        onClose={onClose}
        onSuccess={onSuccess}
        sessionId={sessionId}
        setSessionId={setSessionId}
      />
    );
  } else if (step === 2) {
    return (
      <AccountBlockMyAccount
        onClose={onClose}
        onSuccess={onSuccess}
        secondaryError={error}
      />
    );
  } else if (step === 3) {
    return (
      <AccountAuthenticationOtp
        onAuthSuccess={handleBlockAccount}
        loading={loading}
        setLoading={setLoading}
        sessionId={sessionId}
        onRetry={handleAccountNumberValidation}
      />
    );
  } else if (step === 4) {
    return <BlockMyAccountSuccess onClose={onClose} />;
  } else if (step === 5) {
    return (
      <BlockMyAccountFailure
        onClose={onClose}
        onRetry={handleBlockAccount}
        loading={loading}
        error={error}
      />
    );
  } else {
    return null;
  }
};

export default BlockMyAccount;
