import AccountUpgradeIcon from "../Assets/SVGIcons/CardRequestIcon/AccountUpgradeIcon";
import BlockMyAccountIcon from "../Assets/SVGIcons/CardRequestIcon/BlockMyAccountIcon";
import BlockMyCardIcon from "../Assets/SVGIcons/CardRequestIcon/BlockMyCardIcon";
import CardRequestSquareIcon from "../Assets/SVGIcons/CardRequestIcon/CardRequestSquareIcon";
import StatusOfBillPaymentIcon from "../Assets/SVGIcons/CardRequestIcon/StatusOfBillPaymentIcon";

export const features = [
  {
    title: "Block My Account",
    caption: "Suspect Unauthorized activity, quickly block your account NOW.",
    icon: <BlockMyAccountIcon />,
    id: "blockMyAccount",
  },
  {
    title: "Account Upgrade",
    caption: "Enjoy more opportunities by upgrading today",
    icon: <AccountUpgradeIcon />,
    id: "accountUpgrade",
  },
  {
    title: "Status Of Bill Payment",
    caption: "Stay informed, monitor your transactions on the go",
    icon: <StatusOfBillPaymentIcon />,
    id: "statusOfBillPayment",
  },
  {
    title: "Block My Card",
    caption: "Quickly block your bank card via our self-service portal.",
    icon: <BlockMyCardIcon />,
    id: "blockMyCard",
  },
  {
    title: "Card Request",
    caption: "Request credit card instantly",
    icon: <CardRequestSquareIcon />,
    id: "cardRequest",
  },
];
