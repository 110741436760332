// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notofications_container__S7LgZ {
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Gotham;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-bottom: 1rem;
}

.Notofications_error__\\+dxsi {
  background-color: var(--errorBgRed);
  color: var(--red);
}

.Notofications_success__08BoO {
  border-image-source: linear-gradient(0deg, #10b981, #10b981),
    linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
  color: var(--successGreen);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Notofications/Notofications.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,SAAS;EACT,kBAAkB;EAClB,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,iBAAiB;AACnB;;AAEA;EACE;6EAC2E;EAC3E,0BAA0B;AAC5B","sourcesContent":[".container {\n  padding: 8px 16px;\n  gap: 10px;\n  border-radius: 8px;\n  background: rgba(255, 255, 255, 0.9);\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  font-family: Gotham;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: left;\n  margin-bottom: 1rem;\n}\n\n.error {\n  background-color: var(--errorBgRed);\n  color: var(--red);\n}\n\n.success {\n  border-image-source: linear-gradient(0deg, #10b981, #10b981),\n    linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));\n  color: var(--successGreen);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Notofications_container__S7LgZ`,
	"error": `Notofications_error__+dxsi`,
	"success": `Notofications_success__08BoO`
};
export default ___CSS_LOADER_EXPORT___;
