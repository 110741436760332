import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import classes from "./OtpInput.module.css";

type OtpInputTypes = {
  length: number;
  state: string;
  setState: Dispatch<SetStateAction<string>>;
};

const OtpInput = ({ length, state, setState }: OtpInputTypes) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(""));

  // Refs
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  // Utils
  const focusInput = (index: number) => {
    if (index >= 0 && index < length) {
      inputRefs.current[index]?.focus();
    }
  };

  // Effects
  useEffect(() => {
    setState(otp.join(""));
  }, [otp, setState]);

  useEffect(() => {
    if (!state) {
      setOtp(Array(length).fill(""));
    }
  }, [state, length]);

  // Handlers
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    if (value) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value.charAt(0);
      setOtp(updatedOtp);
      focusInput(index + 1);
    }
  };

  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      const updatedOtp = [...otp];
      if (!otp[index]) {
        focusInput(index - 1);
      } else {
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData
      .getData("text")
      .slice(0, length)
      .replace(/[^0-9]/g, "");
    const updatedOtp = [...otp];
    for (let i = 0; i < pasteData.length; i++) {
      if (i < length) {
        updatedOtp[i] = pasteData[i];
      }
    }
    setOtp(updatedOtp);
    focusInput(pasteData.length);
  };

  return (
    <div className={classes.container}>
      {Array.from({ length }).map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength={1}
          className={classes.input}
          value={otp[i]}
          ref={(el) => (inputRefs.current[i] = el)}
          onChange={(e) => handleChange(e, i)}
          onKeyDown={(e) => handleBackspace(e, i)}
          onPaste={handlePaste}
        />
      ))}
    </div>
  );
};

export default OtpInput;
