import React from "react";
import Modal from "../Modal/Modal";
import classes from "./SuccessModal.module.css";

type SuccessModalProps = {
  onClose: () => void;
  icon: React.ReactNode;
  title: string;
  caption?: string;
};

const SuccessModal = ({ onClose, icon, title, caption }: SuccessModalProps) => {
  return (
    <Modal
      onClick={onClose}
      body={
        <div className={classes.successContainer}>
          <button className={classes.closeButton} onClick={onClose}>
            &times;
          </button>
          <div className={classes.iconContainer}>{icon}</div>
          <h2 className={classes.title}>{title}</h2>
          {caption && <p className={classes.caption}>{caption}</p>}
        </div>
      }
    />
  );
};

export default SuccessModal;
