import { Dispatch, SetStateAction, useContext, useState } from "react";
import OtpInput from "../../Components/OtpInput/OtpInput";
import { OTP_LENGTH } from "../../Utilities/constants";
import classes from "./AccountAuthenticationOtp.module.css";
import Button from "../../Components/Button/Button";
import { validateAccountNumberOtp } from "../../Services/services";
import { generateId } from "../../HelperFunctions/generateId";
import Notifications from "../../Components/Notofications/Notofications";
import { UserContext } from "../../Context/UserContext";

type AccountAuthenticationOtpTypes = {
  onAuthSuccess: () => void;
  sessionId?: string;
  onRetry?: () => void;
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
};

const AccountAuthenticationOtp = ({
  onAuthSuccess,
  sessionId,
  onRetry,
  loading,
  setLoading,
}: AccountAuthenticationOtpTypes) => {
  // States
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  // Context
  const { user } = useContext(UserContext);

  // Utils

  const handleOtpVerification = async () => {
    if (setLoading) {
      setLoading(true);
    }

    setError("");
    try {
      const response = await validateAccountNumberOtp({
        requestId: generateId(),
        otp,
        sessionId: sessionId as string,
      });

      if (response.data) {
        onAuthSuccess();
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);

      setOtp("");
    } finally {
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  const emailUsernameArray = (user?.emailAddress as string)
    ?.toLowerCase()
    ?.split("@");
  const emailUsername = emailUsernameArray[0];
  const last3Letters = emailUsername.slice(-3);
  const maskedEmailAddress = `${last3Letters.padStart(
    emailUsername.length,
    "*"
  )}@${emailUsernameArray[1]}`;

  return (
    <section className={classes.container}>
      <h3>Enter OTP Code</h3>
      {error && <Notifications message={error} severity="error" />}

      <p className={classes.message}>
        Please enter the OTP code sent to {maskedEmailAddress}
      </p>

      <OtpInput length={OTP_LENGTH} state={otp} setState={setOtp} />

      <Button
        onClick={handleOtpVerification}
        disabled={otp.length < OTP_LENGTH}
        loading={loading}
      >
        Verify
      </Button>

      <p>
        OTP will expire in 5 minutes
        <span
          onClick={() => {
            if (onRetry) {
              onRetry();
            }
          }}
        >
          {" "}
          Click to Resend
        </span>
      </p>
    </section>
  );
};

export default AccountAuthenticationOtp;
