const CardRequestRoundIcon = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 1.66699H2.66667C1.19375 1.66699 0 2.86116 0 4.33366V5.66699H24V4.33366C24 2.86116 22.8042 1.66699 21.3333 1.66699ZM0 17.667C0 19.1395 1.19375 20.3337 2.66667 20.3337H21.3333C22.8063 20.3337 24 19.1395 24 17.667V9.66699H0V17.667ZM7.9625 15.3337C7.9625 15.1494 8.11175 15.0003 8.29583 15.0003H14.2583C14.4424 15.0003 14.5917 15.1494 14.5917 15.3337L14.6667 16.0003C14.6667 16.1844 14.5174 16.3337 14.3333 16.3337H8.33333C8.15 16.3337 8 16.1837 8 16.0003L7.9625 15.3337ZM2.62917 15.3337C2.62917 15.1494 2.77842 15.0003 2.9625 15.0003H6.29583C6.47992 15.0003 6.62917 15.1494 6.62917 15.3337L6.66667 16.0003C6.66667 16.1837 6.51667 16.3337 6.33333 16.3337H3C2.81583 16.3337 2.66667 16.1837 2.66667 16.0003L2.62917 15.3337Z"
        fill="#5C068C"
      />
    </svg>
  );
};

export default CardRequestRoundIcon;
