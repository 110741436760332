import CloseIcon from "../../Assets/SVGIcons/CloseIcon/CloseIcon";
import Button from "../../Components/Button/Button";
import UserCard from "../../Components/UserCard/UserCard";
import { cardDetails } from "../../Utilities/cardDetails";
import classes from "./ViewCards.module.css";

type ViewCardsTypes = {
  onClose: () => void;
  onSuccess: () => void;
};

const ViewCards = ({ onClose, onSuccess }: ViewCardsTypes) => {
  return (
    <section className={classes.container}>
      <h3>Account Name</h3>
      <CloseIcon onClose={onClose} />

      <div className={classes.accountNameSection}>
        <h4>Account Name</h4>
        <p>Tosin Faulkner</p>
      </div>

      <div className={classes.cardsSection}>
        <h4>All Cards</h4>

        {cardDetails?.map((data, i) => {
          return (
            <div key={i}>
              <UserCard />

              <Button type="secondary" onClick={onSuccess}>
                Block Card
              </Button>
            </div>
          );
        })}
      </div>

      <p>
        NB: If you block your card you will only be able to activate it by
        visiting a nearest FCMB branch.
      </p>
    </section>
  );
};

export default ViewCards;
