import { createContext, Dispatch, SetStateAction, useState } from "react";
import { userType } from "../Types";

type UserContextValueTypes = {
  user: userType | null;
  setUser: Dispatch<SetStateAction<userType | null>>;
};

type UserContextProviderTypes = {
  children: React.ReactNode;
};

export const UserContext = createContext({} as UserContextValueTypes);

const UserContextProvider = ({ children }: UserContextProviderTypes) => {
  // States
  const [user, setUser] = useState<userType | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
