export const capitalizeEachWord = (text: string) => {
  const destructuredStringArray = text.split(" ");
  let capitalizedString = [];

  for (let i = 0; i < destructuredStringArray.length; i++) {
    capitalizedString.push(capitalize(destructuredStringArray[i]));
  }
  return capitalizedString.join(" ");
};

export const capitalize = (text: string) => {
  const firstLetterOfWord = text[0].toUpperCase();

  return `${firstLetterOfWord}${text.slice(1).toLowerCase()}`;
};
