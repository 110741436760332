// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_container__Vl9P4 {
  position: relative;
}

.Layout_container__Vl9P4 > header {
  height: 64px;
  position: sticky;
  top: 0;
  background-color: var(--white);
}

.Layout_container__Vl9P4 > section {
  min-height: calc(100vh - 64px);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,MAAM;EACN,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".container {\n  position: relative;\n}\n\n.container > header {\n  height: 64px;\n  position: sticky;\n  top: 0;\n  background-color: var(--white);\n}\n\n.container > section {\n  min-height: calc(100vh - 64px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Layout_container__Vl9P4`
};
export default ___CSS_LOADER_EXPORT___;
