import { useContext, useState } from "react";
import CloseIcon from "../../Assets/SVGIcons/CloseIcon/CloseIcon";
import Button from "../../Components/Button/Button";
import classes from "./AccountUpgradeAccount.module.css";
import { validateAccountNumber } from "../../Services/services";
import { generateId } from "../../HelperFunctions/generateId";
import Notifications from "../../Components/Notofications/Notofications";
import { UserContext } from "../../Context/UserContext";
import { capitalizeEachWord } from "../../HelperFunctions/capitalize";

type AccountUpgradeAccountTypes = {
  onClose: () => void;
  onSuccess: () => void;
  secondaryError: string;
};

const AccountUpgradeAccount = ({
  onClose,
  onSuccess,
  secondaryError,
}: AccountUpgradeAccountTypes) => {
  // States
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Utils
  const handleUpgradeMyAccount = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await validateAccountNumber({
        requestId: generateId(),
        accountNumber: user?.accountNumber as string,
      });

      if (response?.data) {
        onSuccess();
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  // Context
  const { user } = useContext(UserContext);

  return (
    <section className={classes.container}>
      <CloseIcon onClose={onClose} />

      <h3>Account Upgrade</h3>

      {error ||
        (secondaryError && (
          <Notifications message={error || secondaryError} severity="error" />
        ))}

      <div className={classes.accountNameSection}>
        <h4>Account Name</h4>
        <p> {capitalizeEachWord(user?.accountName as string)}</p>
      </div>
      <div className={classes.accountNameSection}>
        <h4>Account Tier</h4>
        <p> {user?.tiertype ? `Tier ${user?.tiertype}` : "No tier found"}</p>
      </div>
      <Button
        onClick={handleUpgradeMyAccount}
        loading={loading}
        disabled={!user?.tiertype}
      >
        {user?.tiertype
          ? `Upgrade To Tier ${Number(user.tiertype) + 1}`
          : "Cannot upgrade this account"}
      </Button>
    </section>
  );
};

export default AccountUpgradeAccount;
