import FailureIcon from "../../Assets/SVGIcons/FailureIcon/FailureIcon";
import FailureModal from "../../Components/FailureModal/FailureModal";

type FailureModalTypes = {
  onClose: () => void;
  onRetry?: () => void;
  loading?: boolean;
  error?: string;
};

const BlockMyAccountFailure = ({
  onClose,
  onRetry,
  loading,
  error,
}: FailureModalTypes) => {
  const retryAction = () => {
    if (onRetry) onRetry();
  };

  const failureIcon = <FailureIcon />;

  return (
    <div>
      <FailureModal
        onClose={onClose}
        onRetry={retryAction}
        icon={failureIcon}
        title={error || "Account could not be blocked an error occured"}
        loading={loading}
      />
    </div>
  );
};

export default BlockMyAccountFailure;
