// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SuccessModal_successContainer__mRPxZ {
  text-align: center;
  padding: 20px;
  position: relative;
  width: 493px;
  min-height: 298px;
}

.SuccessModal_iconContainer__IRw4t {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}

.SuccessModal_title__eVZMB {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 25px;
}

.SuccessModal_caption__1OcGh {
  color: #555;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}

.SuccessModal_closeButton__NyYqj {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SuccessModal/SuccessModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb","sourcesContent":[".successContainer {\n  text-align: center;\n  padding: 20px;\n  position: relative;\n  width: 493px;\n  min-height: 298px;\n}\n\n.iconContainer {\n  display: flex;\n  justify-content: center;\n  margin: 30px auto;\n}\n\n.title {\n  font-size: 1.5rem;\n  color: #000;\n  margin-bottom: 25px;\n}\n\n.caption {\n  color: #555;\n  font-family: Montserrat;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0.10000000149011612px;\n  text-align: center;\n}\n\n.closeButton {\n  position: absolute;\n  top: 10px;\n  right: 15px;\n  background: none;\n  border: none;\n  font-size: 1.5rem;\n  cursor: pointer;\n  color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successContainer": `SuccessModal_successContainer__mRPxZ`,
	"iconContainer": `SuccessModal_iconContainer__IRw4t`,
	"title": `SuccessModal_title__eVZMB`,
	"caption": `SuccessModal_caption__1OcGh`,
	"closeButton": `SuccessModal_closeButton__NyYqj`
};
export default ___CSS_LOADER_EXPORT___;
