import classes from "./CloseIcon.module.css";

type CloseIconTypes = {
  onClose: () => void;
};

const CloseIcon = ({ onClose }: CloseIconTypes) => {
  return (
    <div className={classes.svg}>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClose}
      >
        <g clipPath="url(#clip0_38522_2748)">
          <path
            d="M8.87423 7.51217L14.7149 1.67129C15.095 1.29136 15.095 0.677076 14.7149 0.297151C14.335 -0.0827742 13.7207 -0.0827742 13.3408 0.297151L7.49992 6.13803L1.65921 0.297151C1.27911 -0.0827742 0.665002 -0.0827742 0.285077 0.297151C-0.0950257 0.677076 -0.0950257 1.29136 0.285077 1.67129L6.12578 7.51217L0.285077 13.353C-0.0950257 13.733 -0.0950257 14.3473 0.285077 14.7272C0.474417 14.9167 0.72337 15.0119 0.972145 15.0119C1.22092 15.0119 1.4697 14.9167 1.65921 14.7272L7.49992 8.8863L13.3408 14.7272C13.5303 14.9167 13.7791 15.0119 14.0279 15.0119C14.2766 15.0119 14.5254 14.9167 14.7149 14.7272C15.095 14.3473 15.095 13.733 14.7149 13.353L8.87423 7.51217Z"
            fill="#262833"
          />
        </g>
        <defs>
          <clipPath id="clip0_38522_2748">
            <rect width="15" height="15" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CloseIcon;
